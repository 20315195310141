<template>
  <div class="home">
    <v-card v-if="renderComponent" class="component-card" v-click-outside="{ handler: closeCard, include: include}">
      <component :is="renderComponent" :key="renderComponent" @close="closeCard" @showVideoPopUp="showOrHideVideoPopUp"></component>
    </v-card>
    <div class="presentation">
      <div class="homeText">{{ $t('homeText') }}</div>
      <div class="imgContainer"></div>
    </div>
    <div class="buttons-container">
      <v-card v-for="(button, i) in buttons" :key="i" class="mx-1 button-container" @click="clickButton(button)">
        <v-card-text class="button-content" :class="{ 'active-buton': activeButton === button }">
          <template v-if="!button.loading">
            <img v-if="button.iconFromImages" class="mb-2" :src="`img/icons/${button.icon}`"/>
            <v-icon v-else color="var(--primary)">{{ button.icon }}</v-icon>
            <div>{{ $t(button.text) }}</div>
            <div>{{ $t(button.subtext) }}</div> 
          </template>
          <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
        </v-card-text>
      </v-card>
    </div>
    <v-dialog max-width="90vh" :value="videoPopUp" v-click-outside="clickOutSideVideoPopUp" @keydown.esc="resetVideo()">
      <VideoUsageGuide v-if="videoPopUp" :config="videoPopUp" @hideVideoPopUp="showOrHideVideoPopUp"></VideoUsageGuide>
    </v-dialog>
  </div>
</template>

<script>
import { firebaseTools } from "@/mixins/firebase-tools.js";
import DownloadExamples from "@/components/DownloadExamples";
import LastProcess from "@/components/LastProcess";
import UsageGuide from "@/components/UsageGuide";
import VideoUsageGuide from "@/components/VideoUsageGuide";

export default {
  name: "Home",
  mixins: [firebaseTools],
  components: {
    DownloadExamples,
    LastProcess,
    UsageGuide,
    VideoUsageGuide
  },
  data() {
    return {
      renderComponent: undefined,
      activeButton: undefined,
      videoPopUp: undefined,
      buttons: [
        {
          icon: 'mdi-cloud-download',
          text: 'downloadAllExamples',
          subtext: "beginWithTrafis",
          renderComponent: 'DownloadExamples'
        },
        {
          icon: 'mdi-clipboard-text',
          text: 'lastProcess',
          subtext: "consultLastProcess",
          renderComponent: 'LastProcess'
        },
        {
          icon: 'Vector.svg',
          iconFromImages: true,
          text: 'userManual',
          subtext: "checkManual",
          action: 'downloadUserManual',
          loading: false
        },
        {
          icon: 'mdi-video',
          text: 'usageGuide',
          subtext: "learnUseTrafis",
          renderComponent: 'UsageGuide'
        },
      ],
    }
  },
  methods: {
    include() {
      const selectors = [
        document.querySelector('.v-dialog'),
        document.querySelector('video'),
        document.querySelector('.video-container'),
        document.querySelector('.video-container__button'),
        document.querySelector('.v-overlay__scrim'),
        ...document.querySelectorAll('.button-container')
      ];

      const validSelectors = selectors.filter(Boolean);

      return document.querySelector('.v-overlay__scrim') ? validSelectors : Array.from(document.querySelectorAll('.button-container'));
    },
    closeCard() {
      this.renderComponent = undefined;
      this.activeButton = undefined
    },
    downloadUserManual(button) {
      button.loading = true;
      const nameFile = "user_manual.pdf"; 
      const path = `documentation/${nameFile}`;
      const fileRef = this.createStorageReference(path);
      this.downloadStorageFile(fileRef, nameFile).finally(() => {
        button.loading = false;
        this.activeButton = undefined;
      });
    },
    clickButton(button) {
      this.activeButton = button;
      const { renderComponent, action } = button || {};
      if (renderComponent) this.renderComponent = renderComponent;
      else if (action) {
        this.renderComponent = undefined;
        this[action](button);
      }
    },
    clickOutSideVideoPopUp(event) {
      if (event.target && event.target.classList.contains("v-overlay__scrim")) this.resetVideo();
    },
    resetVideo() {
      this.videoPopUp = undefined;
    },
    showOrHideVideoPopUp(video) {
      this.videoPopUp = video;
    }
  },
};
</script>
<style lang="scss" scoped>
.home {
  position: relative;
  height: 100vh;
  
  .component-card{
    position: absolute;
    right: 0;
    box-shadow: 0px 0px 10px 0px #00000033 !important;
    max-height: calc(100vh - 268px);
    padding: 20px;
    min-width: 420px;
    background-color: var(--bgApplicationColor);
    bottom: 180px !important;
    @media (min-width: 1380px)  {
      bottom: 200px !important;
    }
    
  }


  .presentation {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 80px;
    gap: 80px;
    align-items: center;
    padding: 0px 50px;
    padding: 0px 50px 0px 50px;
    max-width: 1224px;
    width: 100%;

    .homeText {
      width: 600px;
      font-size: 35px;
      font-size: 35px;
      line-height: 56.25px;
    }
    .imgContainer {
      background-image: var(--imgHome);
      height: 370px;
      width: 250px;
      height: 370px;
      width: 250px;
      background-position: center;
      background-size: contain;
      @media(min-width: 1600px){
      height: 400px;
      width: 280px;
    }
      @media(min-width: 1600px){
      height: 400px;
      width: 280px;
    }
    }
  }

  .buttons-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 265px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    

    .button-container {
      cursor: pointer;
      width: 306px;
      height: 160px;
      height: 160px;
      border-radius: 10px 10px 0 0 !important;
      background-color: var(--bgApplicationColor);
    

      .button-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 10px;
        height: 100%;

        .v-progress-circular {
          margin: auto;
        }

        div:nth-of-type(1) {
          line-height: 23px;
          font-weight: 500;
          font-size: 20px;
          color: var(--fontColor);
          @media(max-width: 1380px){
            font-size: 16px;
          }
          @media(max-width: 1380px){
            font-size: 16px;
          }
        }

        div:nth-of-type(2) {
          line-height: 18.5px;
          font-size: 16px;
          color: var(--fontColorSecondary);
          @media(max-width: 1380px){
            font-size: 14px;
          }
          @media(max-width: 1380px){
            font-size: 14px;
          }
        }

        .v-icon {
          font-size: 48px;
          line-height: 48px;
          color: var(--primary);
          @media(max-width: 1380px){
            font-size: 40px;
          }
          
          @media(max-width: 1380px){
            font-size: 40px;
          }
          
        }

        &.active-buton {
          background-color: #9C40C71A;
          div:nth-of-type(1),
          div:nth-of-type(2){
            color: #9C40C7;
          }
        }
      }
    }
  }
}
::v-deep .v-dialog {
  border-radius: 10px;
}
</style>
